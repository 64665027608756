import React, { useEffect, useRef, useState } from 'react';
import { Timer as TimerIcon } from 'lucide-react';
import './Timer.scss';

interface TimerProps {
  duration: number;
  isConnected: boolean;
  warningThreshold?: number;
  onWarningThreshold: () => Promise<void>;
}

export const Timer: React.FC<TimerProps> = ({
  duration,
  isConnected,
  warningThreshold = 15,
  onWarningThreshold
}) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const hasWarningFiredRef = useRef(false);
  const warningCallbackRef = useRef(onWarningThreshold);

  // Mettre à jour la référence du callback quand il change
  useEffect(() => {
    warningCallbackRef.current = onWarningThreshold;
  }, [onWarningThreshold]);

  // Effet pour réinitialiser le timer quand isConnected change
  useEffect(() => {
    if (!isConnected) {
      // console.log('[TIMER] Réinitialisation du timer');
      hasWarningFiredRef.current = false;
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
      setTimeLeft(duration);
      return;
    }
  }, [isConnected, duration]);

  // Effet principal pour gérer le timer
  useEffect(() => {
    if (!isConnected || timerRef.current) return;

    // console.log('[TIMER] Démarrage du timer');
    setTimeLeft(duration);

    timerRef.current = setInterval(() => {
      setTimeLeft(prevTime => {
        const newTime = prevTime - 1;

        // Déclencher le warning de manière sûre
        if (newTime === warningThreshold && !hasWarningFiredRef.current) {
          // console.log('[TIMER] Déclenchement du warning threshold');
          hasWarningFiredRef.current = true;
          // Utiliser requestAnimationFrame pour éviter les problèmes de rendu
          requestAnimationFrame(() => {
            warningCallbackRef.current().catch(error => {
              console.error('[TIMER] Erreur lors du déclenchement du warning:', error);
            });
          });
        }

        // Gérer la fin du timer
        if (newTime <= 0) {
          // console.log('[TIMER] Fin du timer');
          if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
          }
          return 0;
        }

        return newTime;
      });
    }, 1000);

    // Nettoyage
    return () => {
      // console.log('[TIMER] Nettoyage du timer');
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isConnected, duration, warningThreshold]);

  const formatTimeLeft = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div data-component="Timer">
      <div className="timer-title">Temps restant à l'appel</div>
      <div className={`countdown ${timeLeft <= warningThreshold ? 'warning' : ''}`}>
        <TimerIcon className="timer-icon" />
        {formatTimeLeft(timeLeft)}
      </div>
    </div>
  );
};
