// Welcome.tsx
import React from 'react';
import { Phone, Gift, Clock, MessageCircle } from 'lucide-react';
import './Welcome.scss';

interface WelcomeProps {
  onStart: () => void;
}

export const Welcome: React.FC<WelcomeProps> = ({ onStart }) => {
  return (
    <div data-component="Welcome">
      <div className="welcome-container">
        <div className="welcome-card">
          <div className="welcome-header">
            <h1 className="welcome-title">
              Le Père Noël prend une pause de son atelier pour discuter avec toi !
            </h1>
          </div>

          <div className="welcome-content">
            <div className="info-section">
              <div className="info-item">
                <Gift className="info-icon" />
                <p>
                  Grâce à une technologie magique (et en test !), tu peux parler directement avec le Père Noël.
                  C'est presque comme appeler le Pôle Nord !
                </p>
              </div>

              <div className="info-item">
                <Clock className="info-icon" />
                <p>
                  Tu auras 2 minutes pour discuter avec lui. Le père Noël
                  dirigera la discussion, mais tu peux l'interrompre à tout
                  moment. Tu peux lui poser des questions, lui demander qu'il
                  raconte une histoire ou lui dire ce que tu veux pour Noël!
                </p>
              </div>

              <div className="info-item">
                <MessageCircle className="info-icon" />
                <p>
                  Comme c'est une nouvelle magie en test, il se peut que le Père Noël soit parfois un peu
                  confus ou qu'il mette du temps à répondre. Sois patient avec lui, n'hésite pas à répéter, il fait de son mieux !
                </p>
              </div>
            </div>

            <div className="tip-section">
              <p>
                Psst ! Un secret des lutins : pour une meilleure conversation, assure-toi d'être dans un
                endroit calme et d'utiliser des écouteurs !
              </p>
            </div>
          </div>

          <div className="welcome-footer">
            <button onClick={onStart} className="welcome_button">
              <span className="button-content">
                <span>C'est noté ! Je veux parler au Père Noël</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
