// src/utils/logger.ts

type LogLevel = 'debug' | 'info' | 'warn' | 'error';

interface LoggerOptions {
  namespace?: string;
  enabled: boolean;
  minLevel?: LogLevel;
}

const LOG_LEVELS: Record<LogLevel, number> = {
  debug: 0,
  info: 1,
  warn: 2,
  error: 3,
};

class Logger {
  private namespace: string;
  private enabled: boolean;
  private minLevel: LogLevel;

  constructor(options: LoggerOptions) {
    this.namespace = options.namespace || 'App';
    this.enabled = options.enabled;
    this.minLevel = options.minLevel || 'debug';
  }

  private shouldLog(level: LogLevel): boolean {
    return this.enabled && LOG_LEVELS[level] >= LOG_LEVELS[this.minLevel];
  }

  private formatMessage(level: LogLevel, message: string): string {
    const timestamp = new Date().toISOString();
    return `[${timestamp}] [${this.namespace}] [${level.toUpperCase()}] ${message}`;
  }

  debug(...args: any[]): void {
    if (this.shouldLog('debug')) {
      console.log(this.formatMessage('debug', ''), ...args);
    }
  }

  info(...args: any[]): void {
    if (this.shouldLog('info')) {
      console.info(this.formatMessage('info', ''), ...args);
    }
  }

  warn(...args: any[]): void {
    if (this.shouldLog('warn')) {
      console.warn(this.formatMessage('warn', ''), ...args);
    }
  }

  error(...args: any[]): void {
    if (this.shouldLog('error')) {
      console.error(this.formatMessage('error', ''), ...args);
    }
  }

  create(namespace: string): Logger {
    return new Logger({
      namespace,
      enabled: this.enabled,
      minLevel: this.minLevel
    });
  }
}

// Création de l'instance par défaut basée sur les variables d'environnement
const defaultLogger = new Logger({
  enabled: process.env.REACT_APP_DEBUG_LOGS === 'true',
  minLevel: (process.env.REACT_APP_LOG_LEVEL as LogLevel) || 'debug'
});

export default defaultLogger;
