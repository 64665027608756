// src/components/common/avatar/Avatar.tsx
import React from 'react';
import './Avatar.scss';

interface AvatarProps {
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({ className = '' }) => {
  return (
    <div data-component="Avatar" className={className}>
      <div className="avatar-container">
        <img
          src="/assets/santa-claus.jpeg"
          alt="Père Noël"
          className="avatar-image"
        />
      </div>
      <span className="avatar-name">Père Noël</span>
    </div>
  );
};
