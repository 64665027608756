import { useEffect, useRef, useCallback, useState } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import type { ToolDefinitionType, SessionResourceType, ItemType } from '@openai/realtime-api-beta/dist/lib/client';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';
import { Button } from '../components/common/button/Button';
import { Timer } from '../components/common/timer/Timer';
import { Avatar } from '../components/common/avatar/Avatar';
import { Welcome } from './welcome/Welcome';
import logger from '../utils/logger';
import './ConsolePage.scss';

type ConversationState = 'welcome' | 'disconnected' | 'connected' | 'ending' | 'ended';

const LOCAL_RELAY_SERVER_URL: string = process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
const consoleLogger = logger.create('ConsolePage');

consoleLogger.info("API_BASE_URLS:", API_BASE_URL);

const SESSION_DURATION = 120;

export function ConsolePage() {

  const [apiKey, setApiKey] = useState<string>('');
  const clientRef = useRef<RealtimeClient | null>(null);
  const [conversationState, setConversationState] = useState<ConversationState>('welcome');

  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));

  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);

  const [hasSeenWelcome, setHasSeenWelcome] = useState<boolean>(() => {
    // On peut optionnellement sauvegarder cette préférence dans le localStorage
    // return localStorage.getItem('hasSeenWelcome') === 'true';
    return false;
  });

  const handleWelcomeComplete = useCallback(() => {
    setConversationState('disconnected');
    setHasSeenWelcome(true);
    // Optionnellement sauvegarder dans localStorage
    // localStorage.setItem('hasSeenWelcome', 'true');
  }, []);

  // Initialiser le client avec la clé API
  useEffect(() => {
    const initClient = async () => {
        try {
            consoleLogger.info("Initialisation du client...");
            const response = await fetch(`${API_BASE_URL}/api/connect`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            consoleLogger.info("Statut de la réponse:", response.status);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            consoleLogger.info("Données reçues:", { ...data, api_key: "***" });

            if (!data.api_key) {
                throw new Error("Pas de clé API reçue");
            }

            setApiKey(data.api_key);

            consoleLogger.info("Création du client RealtimeAPI...");
            clientRef.current = new RealtimeClient({
                apiKey: data.api_key,
                dangerouslyAllowAPIKeyInBrowser: true
            });
            consoleLogger.info("Client créé avec succès");

        } catch (error) {
            consoleLogger.error('Erreur lors de l\'initialisation du client:', error);
        }
    };

    initClient();
  }, []);

  const disconnectConversation = useCallback(async () => {
    consoleLogger.info("=== DÉMARRAGE DÉCONNEXION ===", {
      isConnected: clientRef.current?.isConnected(),
      conversationState
    });

    if (conversationState === 'welcome' || conversationState === 'ended') {
      consoleLogger.info("[DISCONNECT] Déconnexion ignorée pour l'état:", conversationState);
      return;
    }

    try {
      setConversationState('ending');

      // 1. Arrêt de l'enregistrement audio
      const wavRecorder = wavRecorderRef.current;
      if (wavRecorder) {
        try {
          if (wavRecorder.getStatus() === 'recording') {
            consoleLogger.info("[DISCONNECT] Arrêt de l'enregistrement audio...");
            await wavRecorder.pause();
          }

          // Arrêt des pistes audio
          const stream = wavRecorder.stream;
          if (stream) {
            consoleLogger.info("[DISCONNECT] Arrêt des pistes audio:", stream.getTracks().length);
            stream.getTracks().forEach(track => {
              track.stop();
              consoleLogger.info("[DISCONNECT] Piste audio arrêtée:", track.kind);
            });
          }

          await wavRecorder.clear();
          consoleLogger.info("[DISCONNECT] Enregistreur audio nettoyé avec succès");
        } catch (error) {
          consoleLogger.error('[DISCONNECT] Erreur lors de l\'arrêt de l\'enregistreur:', error);
          // On continue malgré l'erreur pour nettoyer le reste
        }
      }

      // 2. Arrêt du lecteur audio
      const wavStreamPlayer = wavStreamPlayerRef.current;
      if (wavStreamPlayer) {
        try {
          consoleLogger.info("[DISCONNECT] Interruption du lecteur audio...");
          await wavStreamPlayer.interrupt();
          consoleLogger.info("[DISCONNECT] Lecteur audio arrêté avec succès");
        } catch (error) {
          consoleLogger.error('[DISCONNECT] Erreur lors de l\'arrêt du lecteur:', error);
          // On continue malgré l'erreur
        }
      }

      // 3. Déconnexion du client OpenAI
      const client = clientRef.current;
      if (client?.isConnected()) {
        try {
          consoleLogger.info("[DISCONNECT] Déconnexion du client OpenAI...");
          await client.disconnect();
          consoleLogger.info("[DISCONNECT] Client OpenAI déconnecté avec succès");
        } catch (error) {
          consoleLogger.error('[DISCONNECT] Erreur lors de la déconnexion du client OpenAI:', error);
          // On continue pour appeler l'API de déconnexion
        }
      }

      // 4. Appel de l'API de déconnexion
      try {
        consoleLogger.info("[DISCONNECT] Appel de l'API de déconnexion...");
        const response = await fetch(`${API_BASE_URL}/api/disconnect`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
        consoleLogger.info("[DISCONNECT] API de déconnexion appelée avec succès");
      } catch (error) {
        consoleLogger.error('[DISCONNECT] Erreur lors de l\'appel à l\'API de déconnexion:', error);
        // On continue pour finaliser la déconnexion côté client
      }

      // 5. Finalisation de la déconnexion
      setConversationState('ended');
      consoleLogger.info("=== DÉCONNEXION TERMINÉE AVEC SUCCÈS ===");

    } catch (error) {
      consoleLogger.error('[DISCONNECT] Erreur fatale lors de la déconnexion:', error);
      // En cas d'erreur fatale, on force l'état à 'ended'
      setConversationState('ended');
      consoleLogger.info("=== DÉCONNEXION TERMINÉE AVEC ERREUR ===");
    }
  }, [conversationState, API_BASE_URL]);

  const connectConversation = useCallback(async () => {
    consoleLogger.info("=== DÉMARRAGE CONNEXION ===", {
      currentState: conversationState,
      clientConnected: clientRef.current?.isConnected()
    });

    if (conversationState !== 'disconnected') {
      consoleLogger.info("[CONNECT] Connexion impossible dans l'état actuel:", conversationState);
      return;
    }

    if (conversationState !== 'disconnected') {
      consoleLogger.info("[CONNECT] Connexion impossible dans l'état actuel:", conversationState);
      return;
    }

    if (!clientRef.current) {
      consoleLogger.error("[CONNECT] Client non initialisé");
      return;
    }

    try {
      const client = clientRef.current;
      const wavRecorder = wavRecorderRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;

      // 1. Initialisation de l'enregistreur audio
      try {
        consoleLogger.info("[CONNECT] Initialisation de l'enregistreur audio...");
        await wavRecorder.begin();
        consoleLogger.info("[CONNECT] Enregistreur audio initialisé");
      } catch (error) {
        consoleLogger.error("[CONNECT] Erreur lors de l'initialisation de l'enregistreur:", error);
        await disconnectConversation();
        return;
      }

      // 2. Connexion du lecteur audio
      try {
        consoleLogger.info("[CONNECT] Connexion du lecteur audio...");
        await wavStreamPlayer.connect();
        consoleLogger.info("[CONNECT] Lecteur audio connecté");
      } catch (error) {
        consoleLogger.error("[CONNECT] Erreur lors de la connexion du lecteur:", error);
        await disconnectConversation();
        return;
      }

      // 3. Configuration et connexion du client OpenAI
      try {
        consoleLogger.info("[CONNECT] Configuration du client OpenAI...");
        client.updateSession({
          modalities: ["text", "audio"],
          instructions: instructions,
          voice: "ash",
          turn_detection: {
            type: "server_vad",
            threshold: 0.7,
            prefix_padding_ms: 440,
            silence_duration_ms: 500
          }
        });
        await client.connect();
        consoleLogger.info("[CONNECT] Client OpenAI connecté");

        setConversationState('connected');

        consoleLogger.info("[CONNECT] Envoi du message de démarrage...");
        client.sendUserMessageContent([{
          type: "input_text",
          text: "/start_greeting_pere_noel" // Modifier la commande
        }]);

        consoleLogger.info("[CONNECT] Message de démarrage envoyé");

      } catch (error) {
        consoleLogger.error("[CONNECT] Erreur lors de la connexion au client OpenAI:", error);
        await disconnectConversation();
        return;
      }

      // 4. Démarrage de l'enregistrement
      try {
        consoleLogger.info("[CONNECT] Démarrage de l'enregistrement...");
        await wavRecorder.record((data) => client.appendInputAudio(data.mono));
        consoleLogger.info("[CONNECT] Enregistrement démarré avec succès");
      } catch (error) {
        consoleLogger.error("[CONNECT] Erreur lors du démarrage de l'enregistrement:", error);
        await disconnectConversation();
        return;
      }

      consoleLogger.info("=== CONNEXION TERMINÉE AVEC SUCCÈS ===");

    } catch (error) {
      consoleLogger.error("[CONNECT] Erreur générale lors de la connexion:", error);
      setConversationState('ended');
    }
  }, [conversationState, disconnectConversation]);

  useEffect(() => {
    // Ne pas initialiser la visualisation si nous sommes sur l'écran d'accueil
    if (conversationState === 'welcome') {
      return;
    }

    let isLoaded = true;
    let animationFrameId: number;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    consoleLogger.info('Visualisation debug:', {
      state: conversationState,
      wavRecorder: !!wavRecorder,
      clientCanvas: !!clientCanvas,
      wavStreamPlayer: !!wavStreamPlayer,
      serverCanvas: !!serverCanvas
    });

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(clientCanvas, clientCtx, result.values, '#0099ff', 10, 0, 8);
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(serverCanvas, serverCtx, result.values, '#009900', 10, 0, 8);
          }
        }
        animationFrameId = window.requestAnimationFrame(render);
      }
    };

    render();

    return () => {
      consoleLogger.info('Cleanup visualisation effect, state:', conversationState);
      isLoaded = false;
      if (animationFrameId) {
        window.cancelAnimationFrame(animationFrameId);
      }
    };
  }, [conversationState]); // Ajout de conversationState comme dépendance

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;
    const currentConversationState = conversationState;
    if (!client || currentConversationState !== 'connected') {
      consoleLogger.info("Client non disponible ou non connecté");
      return;
    }

    consoleLogger.info("Configuration de la session...");

    // Configuration de la session
    const sessionConfig: SessionResourceType = {
      modalities: ["text", "audio"],
      instructions: instructions + `
        Special commands:
        When you receive "/trigger_goodbye", you should:
        Say "Oh là là, comme le temps passe vite ! Il est déjà l'heure de mon rendez-vous avec Mère Noël. Tu sais, il ne faut jamais faire attendre ceux qu'on aime! Passe un joyeux Noël, mon petit lutin! Ho ho ho!"
      `
    };

    // Configuration des outils
    const goodbyeMessageTool: ToolDefinitionType = {
      name: 'goodbye_message',
      description: 'Used to trigger the goodbye message from Santa',
      parameters: {
        type: 'object',
        properties: {}
      }
    };

    // Gestionnaires d'événements
    const handlers = {
      error: (event: any) => {
        consoleLogger.error("Erreur client reçue:", event);
      },

      interrupt: async () => {
        // Ne pas permettre l'interruption pendant la séquence finale
        if (conversationState === 'ending') {
          consoleLogger.info("[INTERRUPT] Interruption ignorée - séquence de fin en cours");
          return;
        }

        try {
          consoleLogger.info("[INTERRUPT] Tentative d'interruption...");
          const trackSampleOffset = await wavStreamPlayer.interrupt();
          if (trackSampleOffset?.trackId) {
            const { trackId, offset } = trackSampleOffset;
            await client.cancelResponse(trackId, offset);
            consoleLogger.info("[INTERRUPT] Interruption réussie");
          }
        } catch (error) {
          consoleLogger.error("[INTERRUPT] Erreur lors de l'interruption:", error);
        }
      },

      update: async ({ item, delta }: { item: ItemType; delta: { audio?: Int16Array } }) => {
        if (delta?.audio) {
          try {
            await wavStreamPlayer.add16BitPCM(delta.audio, item.id);
          } catch (error) {
            consoleLogger.error("[UPDATE] Erreur lors de l'ajout d'audio:", error);
          }
        }
      },

      goodbye: async () => {
        consoleLogger.info("[GOODBYE TOOL] Fonction goodbye_message appelée");
        return { status: 'goodbye_confirmed' };
      }
    };

    // Configuration initiale
    try {
      consoleLogger.info("[SETUP] Mise à jour de la configuration de session...");
      client.updateSession(sessionConfig);

      consoleLogger.info("[SETUP] Ajout de l'outil goodbye_message...");
      client.addTool(goodbyeMessageTool, handlers.goodbye);

      // Ajout des écouteurs d'événements
      consoleLogger.info("[SETUP] Configuration des gestionnaires d'événements...");
      client.on('error', handlers.error);
      client.on('conversation.interrupted', handlers.interrupt);
      client.on('conversation.updated', handlers.update);

      consoleLogger.info("[SETUP] Configuration terminée avec succès");
    } catch (error) {
      consoleLogger.error("[SETUP] Erreur lors de la configuration:", error);
    }

    // Nettoyage
    return () => {
      consoleLogger.info("[CLEANUP] Début du nettoyage...");
      try {
        client.off('error', handlers.error);
        client.off('conversation.interrupted', handlers.interrupt);
        client.off('conversation.updated', handlers.update);
        client.removeTool('goodbye_message');
        consoleLogger.info("[CLEANUP] Nettoyage terminé avec succès");
      } catch (error) {
        consoleLogger.error("[CLEANUP] Erreur lors du nettoyage:", error);
      }
    };
  }, [conversationState, disconnectConversation]);

  const triggerGoodbye = useCallback(async (): Promise<void> => {
    consoleLogger.info("[GOODBYE] Tentative de déclenchement du goodbye...");
    const client = clientRef.current;

    if (!client?.isConnected() || conversationState !== 'connected') {
      consoleLogger.info("[GOODBYE] Impossible de déclencher le goodbye - État:", conversationState);
      return;
    }

    try {
      consoleLogger.info("[GOODBYE] Démarrage de la séquence...");

      // Flags pour suivre la complétion du message
      let isTextComplete = false;
      let isAudioComplete = false;

      // 1. Arrêt immédiat de la réponse en cours si nécessaire
      const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
      if (trackSampleOffset?.trackId) {
        await client.cancelResponse(trackSampleOffset.trackId, trackSampleOffset.offset);
      }

      // 2. Préparer les écouteurs avant d'envoyer le message
      const audioHandler = (event: any) => {
        // AJOUT: Logging détaillé de l'événement audio.done
        consoleLogger.info("[GOODBYE] Événement response.audio.done reçu:", {
          eventId: event.event_id,
          responseId: event.response_id,
          itemId: event.item_id,
          outputIndex: event.output_index,
          contentIndex: event.content_index,
          fullEvent: event // Log de l'événement complet
        });

        if (event.item?.role === 'assistant') {
          isAudioComplete = true;
          consoleLogger.info("[GOODBYE] Audio terminé");
          checkCompletion();
        }
      };

      const textHandler = (event: any) => {
        // AJOUT: Logging détaillé de l'événement text.done
        consoleLogger.info("[GOODBYE] Événement response.text.done reçu:", {
          eventId: event.event_id,
          responseId: event.response_id,
          itemId: event.item_id,
          outputIndex: event.output_index,
          contentIndex: event.content_index,
          text: event.text, // Le texte final
          fullEvent: event // Log de l'événement complet
        });

        if (event.item?.role === 'assistant') {
          isTextComplete = true;
          consoleLogger.info("[GOODBYE] Texte terminé");
          checkCompletion();
        }
      };

      const checkCompletion = () => {
        // AJOUT: Logging de l'état de complétion
        consoleLogger.info("[GOODBYE] État de complétion:", {
          isTextComplete,
          isAudioComplete
        });

        if (isTextComplete && isAudioComplete) {
          consoleLogger.info("[GOODBYE] Message complètement délivré, démarrage de la déconnexion...");
          // Retirer les écouteurs
          client.off('response.audio.done', audioHandler);
          client.off('response.text.done', textHandler);

          setConversationState('ending');
          disconnectConversation().catch(error => {
            consoleLogger.error("[GOODBYE] Erreur lors de la déconnexion:", error);
          });
        }
      };

      // Ajouter les écouteurs
      client.on('response.audio.done', audioHandler);
      client.on('response.text.done', textHandler);

      // AJOUT: Log des écouteurs ajoutés
      consoleLogger.info("[GOODBYE] Écouteurs ajoutés pour response.audio.done et response.text.done");

      // 3. Envoyer le message de goodbye
      consoleLogger.info("[GOODBYE] Envoi du message d'au revoir...");
      await client.sendUserMessageContent([{
        type: "input_text",
        text: "/trigger_goodbye"
      }]);
      consoleLogger.info("[GOODBYE] Message d'au revoir envoyé");

      // 4. Filet de sécurité
      setTimeout(() => {
        // AJOUT: Logging de l'état avant le timeout
        consoleLogger.info("[GOODBYE] Vérification du timeout - État actuel:", {
          isTextComplete,
          isAudioComplete,
          conversationState
        });

        if (!isTextComplete || !isAudioComplete) {
          consoleLogger.info("[GOODBYE] Déconnexion de sécurité après timeout");
          // Retirer les écouteurs
          client.off('response.audio.done', audioHandler);
          client.off('response.text.done', textHandler);

          setConversationState('ending');
          disconnectConversation().catch(consoleLogger.error);
        }
      }, 15000);

    } catch (error) {
      consoleLogger.error("[GOODBYE] Erreur lors de la séquence de goodbye:", error);
      setConversationState('ending');
      disconnectConversation().catch(consoleLogger.error);
    }
  }, [conversationState, disconnectConversation]);

  return (
    <div data-component="ConsolePage" className="simplified">
      {conversationState === 'welcome' ? (
        <Welcome onStart={handleWelcomeComplete} />
      ) : (
        <div className="content-main">
          <Timer
            duration={SESSION_DURATION}
            isConnected={conversationState === 'connected'}
            warningThreshold={30}
            onWarningThreshold={triggerGoodbye}
          />
          <div className="visualization">
            <div className="visualization-entry client">
              <canvas ref={clientCanvasRef} />
            </div>
            <div className="visualization-entry server">
              <canvas ref={serverCanvasRef} />
            </div>
          </div>
          <div className="avatar-button-container">
            <Avatar />
            <div className="content-actions">
              <Button
                isConnected={conversationState === 'connected'}
                onClick={conversationState === 'connected' ? disconnectConversation : connectConversation}
                disabled={conversationState === 'ending'}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
