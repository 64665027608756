// src/components/common/button/Button.tsx
import React from 'react';
import { Phone, Ban } from 'lucide-react';
import './Button.scss';

interface ButtonProps {
  isConnected: boolean;
  onClick: () => void;
  className?: string;
  disabled?: boolean;  // Pour permettre de désactiver le bouton pendant la séquence de fin
}

export const Button: React.FC<ButtonProps> = ({
  isConnected,
  onClick,
  className = '',
  disabled = false,  // Valeur par défaut pour la prop disabled
}) => {
  return (
    <button
      className={`custom-button ${isConnected ? 'stop' : 'start'} ${className}`}
      onClick={onClick}
      disabled={disabled}  // Contrôle l'état désactivé du bouton
    >
      <span className="button-content">
        {isConnected ? (
          <>
            <span>Arrêtez l'appel</span>
            <Ban className="icon" />
          </>
        ) : (
          <>
            <span>Démarrez la discussion</span>
            <Phone className="icon" />
          </>
        )}
      </span>
    </button>
  );
};
